// i18next-extract-mark-ns-start home-page
import React, { FC } from "react";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import cn from "classnames";

import { useWindowSize } from "@/hooks";
import { Button } from "@/components/ui";
import { Layout } from "@/components/layout";
import OvalMobileIllustration from "@/assets/svgs/ovals-mobile-illstr.svg";
import OvalIllustration from "@/assets/svgs/ovals-illstr.svg";
import MediaBuyingIllustration from "@/assets/svgs/media-buying-illustr.svg";
import SecondaryArrowIcon from "@/assets/svgs/icons/arrow-secondary.svg";
import EmailMarketingIllustration from "@/assets/svgs/email-marketing-illstr.svg";
import DevIllustration from "@/assets/svgs/dev-illstr.svg";
import CirclesImage from "@/assets/svgs/circles.svg";

import "@/styles/visually-hidden.css";
import * as styles from "./index.module.css";

const directionsIllustrations = [<EmailMarketingIllustration />, <MediaBuyingIllustration />, <DevIllustration />];

const SMALL_DESKTOP = 1350;
const MOBILE = 650;

type HomePageProps = {
  data: {
    teamPhoto: FileNode;
    office: FileNode;
  };
};

const HomePage: FC<HomePageProps> = ({ data }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const whatWeDoList = t("what_we_do_section.list", { returnObjects: true });
  const directionsList = t("directions_section.list", { returnObjects: true });

  const isSmallDesktop = width < SMALL_DESKTOP;
  const isMobile = width < MOBILE;

  return (
    <Layout>
      <Helmet>
        <title>{t("epc_meta_title")}</title>
        <meta name="description" content={t("epc_meta_content")} />
      </Helmet>
      <div className={styles.introSectionWrapper}>
        <div className={styles.sloganSection}>
          <p className={styles.slogan}>{t("intro_section.slogan")}</p>
          <hr className={styles.line} />
          <p className={styles.description}>{t("intro_section.description")}</p>
          {!isSmallDesktop && <CirclesImage />}
        </div>
        <section className={styles.introSection}>
          <h1 className={styles.title}>{t("intro_section.title")}</h1>
          <GatsbyImage className={styles.bgIntroImageWrapper} image={getImage(data.office)} alt="" />
          <Button
            link
            variant="secondary"
            href={t("intro_section.join_us_link")}
            className={styles.joinButton}
            icon={<SecondaryArrowIcon />}
            iconClassName={styles.joinButtonIcon}
          >
            <span className={styles.joinButtonText}>{t("intro_section.join_us_text")}</span>
          </Button>
        </section>
      </div>
      <section className={styles.aboutUsSection}>
        <h2 className={styles.aboutUsTitle}>{t("about_us_section.title")}</h2>
        <p className={styles.aboutUsDescription}>{t("about_us_section.description")}</p>
      </section>
      <section className={styles.companyLifeSection}>
        <GatsbyImage className={styles.bgCompanyLifeImageWrapper} image={getImage(data.teamPhoto)} alt="" />
        <h2 className={styles.companyLifeTitle}>{t("life_in_company_section.title")}</h2>
        <Button
          link
          local
          href="/teamlife"
          variant="secondary"
          className={styles.companyLifeButton}
          iconClassName={styles.companyLifeButtonIcon}
          icon={<SecondaryArrowIcon />}
        >
          <span className={styles.companyLifeButtonText}>{t("life_in_company_section.button")}</span>
        </Button>
      </section>
      <section className={styles.directionsSection}>
        <h2 className={styles.directionsTitle}>{t("directions_section.title")}</h2>
        <ul className={styles.directionsList}>
          {directionsList.map(({ title, description }, index) => {
            const cls = cn(styles.directionsListItem, { [styles.directionsListItemReverse]: index % 2 !== 0 });

            return (
              <li className={cls} key={title}>
                {directionsIllustrations[index]}
                <div className={styles.directionsItemWrapper}>
                  <h3 className={styles.directionsItemTitle}>{title}</h3>
                  <p className={styles.directionsItemText}>{description}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </section>
      <section className={styles.whatWeDoSection}>
        <h2 className={styles.whatWeDoTitle}>{t("what_we_do_section.title")}</h2>
        <ul className={styles.whatWeDoList}>
          {whatWeDoList.map(({ title, description }) => (
            <li className={styles.whatWeDoListItem} key={title}>
              <div className={styles.whatWeDoListIconWrapper}>
                <SecondaryArrowIcon />
              </div>
              <div className={styles.whatWeDoListItemWrapper}>
                <h3 className={styles.whatWeDoSubTitle}>{title}</h3>
                <p className={styles.whatWeDoDescription}>{description}</p>
              </div>
            </li>
          ))}
        </ul>
      </section>
      <section className={styles.whyUsSection}>
        <h2 className={styles.whyUsTitle}>{t("why_us_section.title")}</h2>
        <div className={styles.whyUsImage}>{isMobile ? <OvalMobileIllustration /> : <OvalIllustration />}</div>
        <ul className="visually-hidden">
          <li>
            <h3>{t("why_us_section.title_experience")}</h3>
            <p>{t("why_us_section.description_experience")}</p>
          </li>
          <li>
            <h3>{t("why_us_section.title_marketing")}</h3>
            <p>{t("why_us_section.description_marketing")}</p>
          </li>
          <li>
            <h3>{t("why_us_section.title_innovation")}</h3>
            <p>{t("why_us_section.description_innovation")}</p>
          </li>
          <li>
            <h3>{t("why_us_section.title_career")}</h3>
            <p>{t("why_us_section.description_career")}</p>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "home-page"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    office: file(relativePath: { eq: "office.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1019, height: 365, layout: FIXED, breakpoints: [1080, 1920])
      }
    }
    teamPhoto: file(relativePath: { eq: "team-photo.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 696, layout: FULL_WIDTH, breakpoints: [1440, 1920])
      }
    }
  }
`;

export default HomePage;
